@import '../../../lib/style/themes/default.less';
@primary-color: #3e4350;
@secondary-color: #407b82;
@text-color: #141414;
@headerandlinks-color: #2b4570;
@exit-color: #66008a;
@icons-color: #572943;
@input-color: #000000;
@hover-color: #25417e;
@page-color: #f7f7f7;

#root p {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 18px !important;
    line-height: 28px !important;
    color: @text-color;
}

#root h1 {
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 64px !important;
    line-height: 60px !important;
    color: #00707d;
}

#root h2 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 36px !important;
    line-height: 42px !important;
    color: #5a6487;
}

#root h3 {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 36px !important;
    line-height: 60px !important;
    color: #5a6487;
}

#root h4 {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 28px !important;
    color: #5a6487;
}

#root .inner a {
    font-weight: bold;
    color: @headerandlinks-color;
    text-decoration: underline;
    text-decoration-color: @primary-color;
    text-decoration-thickness: 1;
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#root .inner a:hover,
#root .inner a:active {
    text-decoration-thickness: 2;
}

#root b,
#root strong {
    font-weight: bold;
}

/* Responsive Adjustments */
@media only screen and (max-width: 1000px) {
    #root h1 {
        font-size: 54px !important;
        word-break: break-word;
    }
    #root h2 {
        line-height: 48px;
    }
    #root h3 {
        line-height: 48px;
    }
    #root h4 {
        line-height: 24px;
    }
}
@import '../../style/themes/index';
@import '../../style/mixins/index';

@collapse-prefix-cls: ~'@{ant-prefix}-collapse';

.@{collapse-prefix-cls} {
  .reset-component();

  background-color: @collapse-header-bg;
  border: @border-width-base @border-style-base @border-color-base;
  border-bottom: 0;
  border-radius: @collapse-panel-border-radius;

  & > &-item {
    border-bottom: @border-width-base @border-style-base @border-color-base;

    &:last-child {
      &,
      & > .@{collapse-prefix-cls}-header {
        border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
      }
    }

    > .@{collapse-prefix-cls}-header {
      position: relative;
      padding: @collapse-header-padding;
      color: @heading-color;
      line-height: @line-height-base;
      cursor: pointer;
      transition: all 0.3s, visibility 0s;
      .clearfix();

      .@{collapse-prefix-cls}-arrow {
        display: inline-block;
        margin-right: 12px;
        font-size: @font-size-sm;
        vertical-align: -1px;

        & svg {
          transition: transform 0.24s;
        }
      }

      .@{collapse-prefix-cls}-extra {
        float: right;
      }

      &:focus {
        outline: none;
      }
    }

    .@{collapse-prefix-cls}-header-collapsible-only {
      cursor: default;
      .@{collapse-prefix-cls}-header-text {
        cursor: pointer;
      }
    }

    &.@{collapse-prefix-cls}-no-arrow {
      > .@{collapse-prefix-cls}-header {
        padding-left: 12px;
      }
    }
  }

  // Expand Icon right
  &-icon-position-right {
    & > .@{collapse-prefix-cls}-item {
      > .@{collapse-prefix-cls}-header {
        padding: @collapse-header-padding;
        padding-right: @collapse-header-padding-extra;

        .@{collapse-prefix-cls}-arrow {
          position: absolute;
          top: 50%;
          right: @padding-md;
          left: auto;
          margin: 0;
          transform: translateY(-50%);
        }
      }
    }
  }

  &-content {
    color: @text-color;
    background-color: @collapse-content-bg;
    border-top: @border-width-base @border-style-base @border-color-base;

    & > &-box {
      padding: @collapse-content-padding;
    }

    &-hidden {
      display: none;
    }
  }

  &-item:last-child {
    > .@{collapse-prefix-cls}-content {
      border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
    }
  }

  &-borderless {
    background-color: @collapse-header-bg;
    border: 0;
  }

  &-borderless > &-item {
    border-bottom: 1px solid @border-color-base;
  }

  &-borderless > &-item:last-child,
  &-borderless > &-item:last-child &-header {
    border-radius: 0;
  }

  &-borderless > &-item > &-content {
    background-color: transparent;
    border-top: 0;
  }

  &-borderless > &-item > &-content > &-content-box {
    padding-top: 4px;
  }

  &-ghost {
    background-color: transparent;
    border: 0;
    > .@{collapse-prefix-cls}-item {
      border-bottom: 0;
      > .@{collapse-prefix-cls}-content {
        background-color: transparent;
        border-top: 0;
        > .@{collapse-prefix-cls}-content-box {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }

  & &-item-disabled > &-header {
    &,
    & > .arrow {
      color: @disabled-color;
      cursor: not-allowed;
    }
  }
}

@import './rtl';
